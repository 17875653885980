import React from 'react';

import Directory from '../../components/directory/directory.component';
import { HomepageContainer } from './homepage.styles';

function HomePage() {
  return (
    <HomepageContainer>
      <Directory/>
      </HomepageContainer>
  );
}

export default HomePage;
